import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const apiUrl = process.env.REACT_APP_APIURL;


const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Include AM/PM
  };
  return date.toLocaleString("en-US", options);
};


const getAMPM = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};


const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const token = localStorage.getItem("essay_jwt");
      const response = await fetch(`${apiUrl}user/myorders`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }

      const orders = await response.json();
      setOrders(orders);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setLoading(false);
    }
  };


  return (
    <div>
      <h5>My Orders</h5>
      <hr />
      {loading ? (
        <div>Loading...</div>
      ) : orders.length === 0 ? (
        <div className="alert alert-info" role="alert">
          No orders found.
        </div>
      ) : (
        orders.map((order, index) => (
          <Link
            key={index}
            to={`/dashboard/orders/${order.order_id}`}
            className="orderitem"
          >
            <div className="card mb-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          ORDER: <span>#{order.order_id}</span>
                        </h4>
                      </div>
                      <div className="col-md-6">
                        <span className="badge rounded-pill bg-success">
                          ${order.o_total}
                        </span>{" "}
                        <span
                          className={`badge rounded-pill ${
                            order.o_status === 0
                              ? "bg-warning" // Pending - Yellow
                              : order.o_status === 1
                              ? "bg-info" // Submitted - Blue
                              : order.o_status === 2
                              ? "bg-primary" // Assigned - Primary
                              : order.o_status === 3
                              ? "bg-secondary" // Revision - Secondary
                              : order.o_status === 4
                              ? "bg-success" // Completed - Green
                              : ""
                          }`}
                        >
                          {order.o_status === 0
                            ? "Pending Payment"
                            : order.o_status === 1
                            ? "Submitted"
                            : order.o_status === 2
                            ? "Assigned"
                            : order.o_status === 3
                            ? "Revision"
                            : order.o_status === 4
                            ? "Completed"
                            : ""}
                        </span>
                      </div>
                    </div>
                    <p className="card-text">
                      <span className="fw-bold">Topic:</span>{" "}
                      <span>{order.o_topic}</span>{" "}
                      <span className="fw-bold">Subject:</span>{" "}
                      <span>{order.o_subject}</span>{" "}
                      <span className="fw-bold">Academic Level:</span>{" "}
                      <span>{order.o_academiclevel}</span>{" "}
                      <span className="fw-bold">Type of paper:</span>{" "}
                      <span>{order.o_type}</span>
                    </p>
                  </div>
                </div>

                <p className="card-text">
                  <p className="card-text">
                    <small className="text-muted">
                      Order created at {formatDate(order.o_datecreated)}
                    </small>
                  </p>
                </p>
              </div>
            </div>
          </Link>
        ))
      )}
    </div>
  );
};

export default OrderList;
