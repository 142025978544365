import React from "react";
import { useLocation } from "react-router-dom";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import LoginForm from "../../components/forms/LoginForm";

const Login = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const reg = params.get("reg");

  return (
    <div>
      <SideBarPageLayout pageTitle="Login">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-body">
                {reg === "success" && (
                  <div className="alert alert-success" role="alert">
                    Registration Successful! You can now login.
                  </div>
                )}
                <h3>Log in</h3>
                <LoginForm />
                <p className="text-small ">
                  Don't have an account?
                  <a href="/register" className="thelink">
                    {" "}
                    Register here.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </SideBarPageLayout>
    </div>
  );
};

export default Login;
