

import React from 'react';
import HomeBanner from "./homeBanner";
import HomeWork from "./homeWork";
import HomeAbout from "./homeAbout";
import HomeTestionials from './HomeTestimonials';
import HomeOnline from './HomeOnline';
import HomeSamples from './HomeSamples';
import "./Home.css";

const Home = () => {
  return (
    <div>
      <HomeBanner />

      <HomeAbout />
      <HomeWork />
      <HomeOnline />

      <HomeSamples />

      <HomeTestionials />
    </div>
  );
};

export default Home;
