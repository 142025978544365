import React, { useState, useEffect } from "react";
import axios from "axios";
const apiUrl = process.env.REACT_APP_APIURL;

const DashboardHome = () => {
  const [dashboardData, setDashboardData] = useState({
    posted: 0,
    assigned: 0,
    submitted: 0,
    underRevision: 0,
    completed: 0,
    totalPaid: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const token = localStorage.getItem("essay_jwt");
      console.log("token", token);
      const response = await axios.get(`${apiUrl}user/dashboardTotals`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setDashboardData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="row">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <DashboardCard
              title="TOTAL PAID"
              value={`$${dashboardData.totalPaid.toFixed(2)}`}
            />
            <DashboardCard title="POSTED ORDERS" value={dashboardData.posted} />
            <DashboardCard title="ASSIGNED" value={dashboardData.assigned} />
            <DashboardCard
              title="SUBMITTED ORDERS"
              value={dashboardData.submitted}
            />
            <DashboardCard
              title="UNDER REVISION"
              value={dashboardData.underRevision}
            />
            <DashboardCard
              title="COMPLETED ORDERS"
              value={dashboardData.completed}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const DashboardCard = ({ title, value }) => {
  return (
    <div className="col-md-4 mb-2">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <h3>{value}</h3>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
