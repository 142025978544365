import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PricingTable from "./PricingTable";

const PricingTabs = () => {
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Writing from scratch</Tab>
          <Tab>Editing & proofreading</Tab>
          <Tab>Problem solving</Tab>
          <Tab>Admissions</Tab>
        </TabList>

        <TabPanel>
          <PricingTable selectedType={1} />
        </TabPanel>
        <TabPanel>
          <PricingTable selectedType={2} />
        </TabPanel>
        <TabPanel>
          <PricingTable selectedType={3} />
        </TabPanel>
        <TabPanel>
          <PricingTable selectedType={4} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default PricingTabs;
