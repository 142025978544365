import React, { useState, useEffect } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_APIURL;

const PaypalButton = ({ orderDetails }) => {
  const navigate = useNavigate();
  const [orderDetailsData, setOrderDetailsData] = useState(orderDetails);
  const token = localStorage.getItem("essay_jwt");
  const [error, setError] = useState(null);

  useEffect(() => {
    setOrderDetailsData(orderDetails);
  }, [orderDetails]);

  const createOrder = async (data, actions) => {
    try {
      setError(null);
      console.log("orderId: ",orderDetailsData.order_id)
      const response = await fetch(`${apiUrl}paypal/createOrder/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ orderId: orderDetailsData.order_id,type:"type" }),
      });

      const orderData = await response.json();
      console.log("orderData:", orderData);
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        setError(errorMessage);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const onApprove = async (data, actions) => {
    setError(null);
    try {
      const response = await fetch(
        `${apiUrl}paypal/orders/${data.orderID}/capture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ orderId: orderDetailsData.order_id }),
        }
      );

      const orderData = await response.json();
      console.log("orderData:", orderData, response.status);

      if (response.status === 200) {
        console.log("Payment captured successfully");
        navigate(`/dashboard/orders/${orderDetailsData.order_id}`);
      } else {
        setError("An error occurred please try again by refreshing the page");
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  return (
    <div>
      {error && <div className="alert alert-danger mt-2">{error}</div>}
      <PayPalButtons
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onError={(err) => {
          console.error(err);
          setError(err.toString());
        }}
      />
    </div>
  );
};

export default PaypalButton;
