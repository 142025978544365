import React, { useState, useEffect } from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
const apiUrl = process.env.REACT_APP_APIURL;
const fileBaseUrl = process.env.REACT_APP_FILEBASEURL;

const Samples = () => {
  const [loading, setLoading] = useState(true);
  const [samplesByCategory, setSamplesByCategory] = useState({});

  useEffect(() => {
    fetch(`${apiUrl}web/getsamples`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const groupedSamples = {};
          data.forEach((sample) => {
            if (!groupedSamples.hasOwnProperty(sample.s_category)) {
              groupedSamples[sample.s_category] = [];
            }
            groupedSamples[sample.s_category].push(sample);
          });
          setSamplesByCategory(groupedSamples);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching samples:", error);
        setLoading(false);
      });
  }, []);

  const getDownloadLink = (fileName) => {
    console.log("file", `${fileBaseUrl}samples/${fileName}`);
    return `${fileBaseUrl}samples/${fileName}`;
  };

  return (
    <SideBarPageLayout pageTitle="Samples">
      <h2>Samples of Our Work</h2>
      <p>
        At tutors2hire , we're proud to showcase our quality and work. We've put
        together some sample academic papers, written by our expert writers to
        show their skills and quality. Take a look at what we can produce with
        the following examples created at a range of levels in our most popular
        subjects. Here is the quality you will expect after placing an order
        with us, take a look for yourself!
      </p>
      {loading ? (
        <p>Loading...</p>
      ) : Object.entries(samplesByCategory).length === 0 ? (
        <div className="alert alert-warning" role="alert">
          We have not published any samples yet please check back later.
        </div>
      ) : (
        Object.entries(samplesByCategory).map(([category, samples], index) => (
          <div key={index}>
            <h3>{category}</h3>
            <div className="list-group mb-3">
              {samples.map((sample, sampleIndex) => (
                <a
                  key={sampleIndex}
                  className="list-group-item d-flex justify-content-between align-items-center"
                  href={getDownloadLink(sample.s_file)}
                  download
                >
                  <div>
                    {sample.s_name}
                    <br></br>
                    <span className="badge bg-secondary mr-2">
                      Level: {sample.s_level}
                    </span>
                    <span className="badge bg-secondary mr-2">
                      Subject: {sample.s_subject}
                    </span>
                    <span className="badge bg-secondary mr-2">
                      Format: {sample.s_format}
                    </span>
                    <span className="badge bg-secondary mr-2">
                      Type: {sample.s_type}
                    </span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        ))
      )}
    </SideBarPageLayout>
  );
};

export default Samples;
