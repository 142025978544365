import React from "react";

const CustomBreadCrump = ({ pagetitle }) => {
  return (
    <div className="page-banner-area bg-white position-relative z-1">
      <div className="container-fluid">
        <div className="page-banner-content" data-cues="slideInUp">
          {/* <h1>{pagetitle}</h1>
          <ul className="ps-0 mb-0 list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <span className="active">{pagetitle}</span>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default CustomBreadCrump;
