import React from "react";
import { useParams } from "react-router-dom";
import PaymentsController from "../../../../components/payments/PaymentsController";
import ViewOrder from "./ViewOrder";
import OrderList from "./OrderList";
import "./Order.css";

const DashboardOrders = () => {
  const { dashorder, dashaction } = useParams();

  switch (dashorder) {
    case undefined:
      // If dashorder is not present, show OrderList
      return <OrderList />;
    default:
      // If dashorder is present, check for dashaction
      switch (dashaction) {
        case "payment":
          // If dashaction is "payment", show PaymentsController
          return <PaymentsController orderNumber={dashorder} />;
        default:
          // If dashaction is not present or not recognized, pass dashorder to ViewOrder
          return <ViewOrder orderNumber={dashorder} />;
      }
  }
};

export default DashboardOrders;
