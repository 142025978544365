import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import orderItems from "../lists/orderItems";
import pricingItems from "../lists/pricingitems";

const CalculatorForm = () => {
  const navigate = useNavigate();

  const [selectedAcademicLevel, setSelectedAcademicLevel] = useState(
    orderItems.academicLevels[1].value
  );
  const [selectedTypeOfPaper, setSelectedTypeOfPaper] = useState(
    orderItems.typesOfPaper[1].value
  );
  const [selectedDeadline, setSelectedDeadline] = useState(
    orderItems.deadlines[0].value
  );
  const [pageCount, setPageCount] = useState(1);

  const handleSelectChange = (key, value) => {
    switch (key) {
      case "selectedAcademicLevel":
        setSelectedAcademicLevel(value);
        break;
      case "selectedTypeOfPaper":
        setSelectedTypeOfPaper(value);
        break;
      case "selectedDeadline":
        setSelectedDeadline(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(
      `/order/${selectedAcademicLevel}/${selectedTypeOfPaper}/${selectedDeadline}/${pageCount}`
    );
  };

  const calculatePriceAndWords = () => {
    console.log(
      "level: " + selectedAcademicLevel + " deadline: " + selectedDeadline
    );

    const wordsPerPage = 275;

    const pricingRow = pricingItems.find((item) =>
      item.data.some((row) => row[0] === selectedDeadline)
    );

    if (!pricingRow) {
      console.error("Selected deadline not found in pricing items");
      return null;
    }

    let columnIndex;
    switch (selectedAcademicLevel) {
      case "High School":
        columnIndex = 1;
        break;
      case "Undergraduate (yrs. 1-2)":
        columnIndex = 2;
        break;
      case "Undergraduate (yrs. 3-4)":
        columnIndex = 3;
        break;
      case "Master's":
        columnIndex = 4;
        break;
      case "Ph.D.":
        columnIndex = 5;
        break;
      default:
        console.error("Selected academic level not recognized");
        return null;
    }

    const selectedPrice = pricingRow.data.find(
      (row) => row[0] === selectedDeadline
    )[columnIndex];

    console.log("selected price: " + selectedPrice);
    const pricePerWord =
      parseFloat(selectedPrice.replace("$", "")) / wordsPerPage;
    const wordCount = pageCount * wordsPerPage;

    const price = wordCount * pricePerWord;

    console.log(
      "wordCount: " +
        wordCount +
        " pricePerWord: " +
        pricePerWord +
        " price " +
        price
    );
    return { wordCount, price };
  };

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: "increment",
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });

  const handleInputChange = (newNumber) => {
    if (!isNaN(newNumber) && newNumber >= 1) {
      setPageCount(newNumber);
    }
  };

  const academicLevelOptions = orderItems.academicLevels;
  const typeOfPaperOptions = orderItems.typesOfPaper;
  const deadlineOptions = orderItems.deadlines;

  const { wordCount, price } = calculatePriceAndWords();

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="calculator-top">
            <h5>Calculate the price</h5>
          </div>
          <div className="row">
            <div className="col-md-12 mb-2">
              <Select
                options={academicLevelOptions}
                value={{
                  value: selectedAcademicLevel,
                  label: selectedAcademicLevel,
                }}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    "selectedAcademicLevel",
                    selectedOption.value
                  )
                }
                placeholder="Select Academic Level"
              />
            </div>
            <div className="col-md-12 mb-2">
              <Select
                options={typeOfPaperOptions}
                value={{
                  value: selectedTypeOfPaper,
                  label: selectedTypeOfPaper,
                }}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    "selectedTypeOfPaper",
                    selectedOption.value
                  )
                }
                placeholder="Select Type of Paper"
              />
            </div>
            <div className="col-md-12 mb-2">
              <Select
                options={deadlineOptions}
                value={{
                  value: selectedDeadline,
                  label:
                    (
                      deadlineOptions.find(
                        (d) => d.value === selectedDeadline
                      ) || {}
                    ).label || "",
                }}
                onChange={(selectedOption) =>
                  handleSelectChange("selectedDeadline", selectedOption.value)
                }
                placeholder="Select Deadline"
              />
            </div>
          </div>
          <div className="row mb-2 align-items-center">
            <div className="col-md-12">
              <p className="text-small">
                Enter number of Pages |{" "}
                <span className="word-count text-primary">
                  {wordCount} Words
                </span>
              </p>
            </div>
            <div className="col-md-12">
              <NumberInput
                aria-label="Quantity Input"
                value={pageCount}
                min={1}
                max={99}
                onChange={(event, newNumber) => {
                  console.log(
                    `${event.type} event: the new value is ${newNumber}`
                  );
                  handleInputChange(newNumber);
                }}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-5">
              <span className="fw-bold">${price.toFixed(2)}</span>
            </div>
            <div className="col-md-7">
              <button type="submit" className="btn btn-block btn-success">
                CONTINUE
              </button>
            </div>
          </div>
        </form>
        <hr></hr>
        <a href="/onlineclasshelp" className="btn btn-block btn-success">
          Online Class Help
        </a>
      </div>
    </div>
  );
};

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
);

export default CalculatorForm;
