// useAuth.js
import { useEffect } from "react";

const useAuth = (navigate) => {
  const isAuthenticated = localStorage.getItem("essay_jwt") !== null;
  const tokenExpiry = localStorage.getItem("expiry");
  const isTokenExpired = tokenExpiry && new Date(tokenExpiry) < new Date();

  useEffect(() => {
    if (!isAuthenticated || isTokenExpired) {
      // Redirect to the login page if the user is not authenticated or the token is expired
      navigate("/login");
    }
  }, [navigate, isAuthenticated, isTokenExpired]);

  return isAuthenticated && !isTokenExpired;
};

export default useAuth;
