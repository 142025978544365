import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../utils/AuthContext";

const apiUrl = process.env.REACT_APP_APIURL;

const LogoutButton = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("essay_jwt");
      await axios.post(`${apiUrl}auth/logout`, null, {
        headers: {
          Authorization: `${token}`,
        },
      });
      logout();
      window.location.href = "/login";
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      className="btn btn-danger"
      onClick={handleLogout}
      disabled={loading}
    >
      {loading ? "Logging out..." : "Logout"}
    </button>
  );
};

export default LogoutButton;
