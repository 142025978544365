import React from "react";
import CustomBreadCrump from "../../components/utils/breadcrump";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import FullWidthLayout from "../../components/utils/fullwidthlayout";

const HowitWorks = () => {
  return (
    <div>
      <FullWidthLayout pageTitle="How it Works">
        <div className="row">
          <div className="col-md-6">
            <h2>How it works for online class, test and proctored exam</h2>
            <p>
              We prefer updating our clients on whatsapp on every progress; we
              understand you might not be online all the time so whatsapp is the
              best way to reach you out. Link to download
              https://whatsapp.com/dl/ . It also helps to connect with our
              customers easily by using tools to automate, sort and quickly
              respond to messages, and keep records to ensure we serve you
              better.
            </p>
            <h2>What if I DONT WANT TO GIVE LOG INS??</h2>
            <p>
              We understand that you may prefer to keep your personal data
              private, we assure you that your personal information is 100% safe
              with us. But if you still prefer not to give us log ins
              credentials , you can send photos on whatsapp as we send answers
              in real time.
            </p>
            <h3>How it works for exam</h3>
            <ul>
              <li>Place your order in 3 simple steps.</li>
              <li>Chat with support and agree the price.</li>
              <li>Support will payment link.</li>
              <li>
                Your exam will be processed and you will be updated on whatsapp
              </li>
            </ul>
            <h3>How it works for proctored</h3>
            <p>
              We have completed more than 5000 exams on Proctor U and Examity.
              We use software to access your laptop and during the exam time you
              only sit in front of your laptop and pretend to be doing the exam
              as we do the exam remotely. More instructions on this will be
              explained on whatsapp. Click here to contact us.
            </p>
            <h3>How it works for online classes</h3>
            <p>
              We can take your whole class, you will provide us details and we
              will handle everything weekly, do all the quiz, homework, final
              exams , discussions and response posts.
            </p>
          </div>
          <div className="col-md-6">
            <h2>How it works</h2>
            <p>
              Some may say that they do not need academic writing services, but
              at our prices everyone needs them. We offer high quality writing
              services at any level of complexity at very reasonable prices.
              Here is how we work:
            </p>
            <h3>Step 1. Filling out the order form</h3>
            <p>
              All you need to do is input all the details you believe will help
              a writer to create the most outstanding paper and proceed with a
              payment after that. If you’re feeling a bit puzzled during the
              process, you can request a phone call, or use a live chat; one of
              our support agents will get in touch and guide you through. It is
              our priority to ensure your experience with us will be a positive
              one.
            </p>
            <h3>Step 2. Writer selection</h3>
            <p>
              After carefully checking all the order details, our support agents
              will assign a suitable writer for your assignment. Note that we
              might need to contact you for additional details if something
              seems amiss in the order instructions. We assure you that our
              experts are fully trained in their respective fields and finding a
              writer will not be a problem. It usually takes only 20 minutes to
              find the appropriate professional for your task.
            </p>
            <h3>Step 3. Research and writing</h3>
            <p>
              Chat with the writer , Once you have reserved the contract amount
              and both sides agreed, your essay writer will start working right
              away. You can chat with the writer online throughout the working
              process to receive updates and share ideas to keep you in the
              driving seat to success.
            </p>
            <h3>Step 4. Delivery of the product</h3>
            <p>
              The moment the writer provides us with the complete paper, we will
              upload it to your personal account, as well as send you an email
              with the file attached to it to make sure you received the order.
              If your due date is approaching fast, we will call you to let you
              know of the completion of your order.
            </p>
            <h3>Step 5. Revision</h3>
            <p>
              Should you feel that the order needs to be revised in any way, you
              are more than welcome to let us know what it is, specifically,
              that you need changed and we will do the rest. Note that as long
              as you need something revised in the order, we are more than eager
              to help out to make it perfect. Your positive experience is our
              top priority.
            </p>
          </div>
        </div>
      </FullWidthLayout>
    </div>
  );
};

export default HowitWorks;
