import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";
import AppRoutes from "./Routes";
import Navbar from "./components/sections/Navbar/Navbar";
import Footer from "./components/sections/Footer/Footer";
import FooterCopyright from "./components/sections/Footer/FooterCopyright";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import AuthProvider from "./components/utils/AuthContext";

function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <Navbar />
          <div className="main">
            <AppRoutes />
          </div>
          <Footer />
          <FooterCopyright />
          <FloatingWhatsApp
            phoneNumber="+19853170769"
            accountName="EssayPearl"
            allowEsc
            allowClickAway
            notification
            notificationSound
            avatar="/assets/images/essayfav.png"
            statusMessage="Typically replies within seconds"
          />
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
