import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaypalButton from "./PaypalButton";

const PayPalPayment = ({ orderDetails }) => {
  // console.log("orderDetails", orderDetails);
  const initialOptions = {
    "client-id":
      "AQjE6eMkCmZNRbEeONprXlxOCrAJ6W8DePTgp9_qByd0r_At6ItGBFJNAyWGjaqx_3UGOVkYKeyzrYIm",
    currency: "USD",
    intent: "capture",
  };
  return (
    <div>
      <h3>PayPal Payment</h3>
      <PayPalScriptProvider options={initialOptions}>
        <PaypalButton orderDetails={orderDetails} />
      </PayPalScriptProvider>
      <p className="fw-bold">Total Payable Amount: ${orderDetails.o_total}</p>
    </div>
  );
};

export default PayPalPayment;
