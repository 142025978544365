import React, { useState, useContext } from "react";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { AuthContext } from "../utils/AuthContext";

const apiUrl = process.env.REACT_APP_APIURL;

const RegisterFormAuth = ({ onRegistered }) => {
  const { login } = useContext(AuthContext);
  const [fullnames, setFullNames] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    try {
      setError(null);
      setLoading(true);

      if (!fullnames.trim()) {
        setError("Please enter your full names");
        return;
      }

      if (!email.trim()) {
        setError("Please enter your email address");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
        return;
      }

      if (!password.trim()) {
        setError("Please enter your password");
        return;
      }

      if (password.length < 6) {
        setError("Password must be at least 6 characters long");
        return;
      }

      const requestData = {
        u_fullnames: fullnames,
        u_email: email,
        u_password: password,
        u_address: address,
      };

      // Only include phone number if it's not empty
      if (phoneNumber.length > 8) {
        requestData.u_phone = phoneNumber;
      }

      const response = await axios.post(`${apiUrl}auth/register`, requestData);

      if (response.status === 200) {
        console.log("Registration successful:", response.data);
        setError(null);

        // Call onRegistered function to notify parent component
        login();
        onRegistered();
      } else {
        setError(response.data.message || "An error occurred.");
      }
    } catch (error) {
      console.error(
        "Registration error:",
        error.response?.data?.message || error.message
      );
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-12 mb-2">
          <label>Full Names:</label>
          <input
            type="text"
            className="form-control"
            value={fullnames}
            onChange={(e) => setFullNames(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>Email Address:</label>
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>Phone Number (optional):</label>
          <PhoneInput
            defaultCountry="us"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>Password:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>Address (optional):</label>
          <input
            type="text"
            className="form-control"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <button
            type="button"
            className="default-btn"
            onClick={handleRegister}
            disabled={loading} // Disable button during loading
          >
            {loading ? "Registering..." : "Register"}
          </button>
          {error && <div className="alert alert-danger mt-2">{error}</div>}
        </div>
      </div>
    </form>
  );
};

export default RegisterFormAuth;
