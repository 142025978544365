import React from "react";
import CustomBreadCrump from "../../components/utils/breadcrump";
import OnlineClassWizard from "../../components/wizards/onlineclasswizard/OnlineClassWizard";
import FullWidthLayout from "../../components/utils/fullwidthlayout";

const OnlineClassHelp = () => {
  return (
    <div>
      <FullWidthLayout pageTitle="Online Class Help">
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <OnlineClassWizard />
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-2">
              <div className="card-body">
                <p>Click the link below for homework help.</p>
                <div className="banner-btn" data-cues="slideInUp">
                  <a href="/order" className="default-btn active">
                    <div className="d-flex align-items-center">
                      Place Order
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h2>Privacy & Security</h2>
                <p>
                  We value our clients' privacy and treat all personal
                  information as private and confidential.
                </p>
                <h2>Original Content</h2>
                <p>
                  Plagiarism is something we do not tolerate. We use multiple
                  plagiarism scanners to ensure the originality of the work you
                  receive, every time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </FullWidthLayout>
    </div>
  );
};

export default OnlineClassHelp;
