import React from "react";

const HomeSamples = () => {
  return (
    <div className="standard-area ptb-100 convinced">
      <div className="convinced">
        <div className="container white">
          <div className="text-center">
            <h2 className="white">Still not convinced?</h2>
            <h5 className="white">
              We've produced some samples of what you can expect from our Essay
              Writing Service - these are created by our writers to show you the
              kind of high-quality work you'll receive. Take a look for
              yourself!
            </h5>
            <a href="/samples" className="default-btn about-btn">
              View Samples
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSamples;
