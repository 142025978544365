import React from "react";
import CustomBreadCrump from "../../components/utils/breadcrump";

const FullWidthLayout = ({ pageTitle, children }) => {
  return (
    <div>
      <CustomBreadCrump pagetitle={pageTitle} />
      <div className="standard-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">{children}</div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthLayout;
