// Dashboard.js
import React from "react";
import {
  useParams,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import DashboardLayout from "../../components/utils/dashboardlayout";
import DashboardHome from "./pages/home/DashboardHome";
import DashboardOrders from "./pages/orders/DashboardOrders";
import DashboardPayments from "./pages/payments/DashboardPayments";
import DashboardProfile from "./pages/profile/DashboardProfile";
import useAuth from "../../components/utils/useAuth";

const Dashboard = () => {
  const { dashpage } = useParams();
  const navigate = useNavigate();
  useAuth(navigate);

  const defaultPage = "home";

  const pageComponents = {
    home: <DashboardHome />,
    orders: <DashboardOrders />,
    payments: <DashboardPayments />,
    profile: <DashboardProfile />,
  };

  const activePageComponent = pageComponents[dashpage || defaultPage];

  return (
    <DashboardLayout
      pageTitle="Dashboard"
      currentPage={dashpage || defaultPage}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              {activePageComponent ? (
                <Routes>
                  <Route path="/" element={activePageComponent} />
                  <Route path="orders/*" element={<DashboardOrders />} />
                  <Route path="payments" element={<DashboardPayments />} />
                  <Route path="profile" element={<DashboardProfile />} />
                </Routes>
              ) : (
                <p>Page content not found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
