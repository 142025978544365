import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_APIURL;

const OrderAssignments = ({ orderNumber }) => {
  const [assignments, setassignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchassignments = async () => {
      try {
        const token = localStorage.getItem("essay_jwt");
        const response = await axios.get(
          `${apiUrl}orders/getassignments/${orderNumber}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setassignments(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching assignments:", error);
        setError(
          "An error occurred while fetching assignments. Please try again."
        );
        setLoading(false);
      }
    };

    fetchassignments();
  }, [orderNumber]);

  if (loading) {
    return <p>Loading assignments...</p>;
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <div>
      <hr />
      <p>Assignment Files</p>
      <ul className="list-group list-group-flush">
        {assignments.length === 0 ? (
          <li className="list-group-item text-danger">
            No assignments have been uploaded yet
          </li>
        ) : (
          assignments.map((solution, index) => (
            <li key={index} className="list-group-item">
              <a href={solution.url} target="_blank" rel="noopener noreferrer">
                {solution.u_filename}
              </a>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default OrderAssignments;
