import React from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import faqItems from "../../components/lists/faqItems";
import AccordionWidget from "../../components/widgets/Accordation";

const FAQ = () => {
  return (
    <div>
      <SideBarPageLayout pageTitle="FAQs">
        <h2>We ensure quality by offering best writing services</h2>
        <p>
          At our company, we take great care to ensure that every order is
          assigned to the most suitable and experienced essay writer on our
          team. We understand that each project is unique and requires a
          tailored approach, which is why we carefully match your order with the
          writer who has the skills and expertise to deliver the best possible
          results. When you place an order with us, you can be confident that
          your instructions will be followed to the letter by your essay writer,
          with all details carefully considered and incorporated into the final
          product.
        </p>
        <h2>Frequently Asked Questions</h2>
        <AccordionWidget items={faqItems} />
      </SideBarPageLayout>
    </div>
  );
};

export default FAQ;
