import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "intasend-inlinejs-sdk";
const apiUrl = process.env.REACT_APP_APIURL;

function IntasendPayment({ orderDetails }) {
  const navigate = useNavigate();
  const [orderDetailsData, setOrderDetailsData] = useState(orderDetails);
  const token = localStorage.getItem("essay_jwt");
  const email = localStorage.getItem("email");
  const fullname = localStorage.getItem("fullname");
  const [error, setError] = useState(null);

  // Splitting fullname into firstName and lastName
  const [firstName, lastName] = fullname.split(" ");

  useEffect(() => {
    setOrderDetailsData(orderDetails);
  }, [orderDetails]);

  useEffect(() => {
    const intaSendInstance = new window.IntaSend({
      publicAPIKey: "ISPubKey_live_35f3297c-22af-4217-b2f4-38a726f305a4",
      live: true, //or true for live environment
    });

    intaSendInstance
      .on("COMPLETE", (response) => {
        console.log("COMPLETE:", response);
        // Call onApprove function when payment is complete
        onApprove( response);
      })
      .on("FAILED", (response) => {
        console.log("FAILED", response);
      })
      .on("IN-PROGRESS", () => {
        console.log("INPROGRESS ...");
      });
  }, []);

  const onApprove = async ( res) => {
    setError(null);
    try {
      const response = await fetch(
        `${apiUrl}intasend/orders/${orderDetails.order_id}/capture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            orderId: orderDetailsData.order_id,
            invoice_id: res.tracking_id,
          }),
        }
      );

      const orderData = await response.json();
      console.log("orderData:", orderData, response.status);

      if (response.status === 200) {
        console.log("Payment captured successfully");
        navigate(`/dashboard/orders/${orderDetailsData.order_id}`);
      } else {
        setError("An error occurred please try again by refreshing the page");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred please try again by refreshing the page");
    }
  };

  // Define button and badge styles
  const buttonStyle = {
    backgroundColor: "#007bff",
    color: "#ffffff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    margin: "10px 0",
    width: "100%",
  };

  const badgeContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  };

  return (
    <div>
      {error && <div className="alert alert-danger mt-2">{error}</div>}
      <div style={badgeContainerStyle}>
        <a href="https://intasend.com/security" target="_blank">
          <img
            src="https://intasend-prod-static.s3.amazonaws.com/img/trust-badges/intasend-trust-badge-no-mpesa-hr-dark.png"
            width="375px"
            alt="IntaSend Secure Payments (PCI-DSS Compliant)"
          />
        </a>
        <strong>
          <a
            style={{
              color: "#fafafa",
              textDecoration: "none",
              fontSize: "0.8em",
              marginTop: "0.6em",
            }}
            href="https://intasend.com/security"
            target="_blank"
          >
            Secured by IntaSend Payments
          </a>
        </strong>
      </div>
      <button
        className="intaSendPayButton"
        style={buttonStyle}
        data-amount={orderDetails.o_total}
        data-currency="USD"
        data-method="M-PESA"
        data-email={email}
        data-first_name={firstName}
        data-last_name={lastName}
        // data-address="Westlands Nairobi"
        // data-city="Nairobi"
        // data-state="Nairobi"
        // data-zipcode="00100"
        // data-country="KE"
        data-api_ref={`Order_${orderDetails.order_id}`}
      >
        Pay Via Card / CASHAPP
      </button>
      <p className="fw-bold">Total Payable Amount: ${orderDetails.o_total}</p>
    </div>
  );
}
export default IntasendPayment;
