import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FullWidthLayout from "../../components/utils/fullwidthlayout";
import OrderWizard from "../../components/wizards/orderwizard/OrderWizard";
import orderItems from "../../components/lists/orderItems";

const Order = () => {
  const { academicLevel, typeOfPaper, deadline, pageCount, style } =
    useParams();

  // Update passedValues when URL parameters change
  const [passedValues, setPassedValues] = useState({
    _academicLevel: academicLevel || orderItems.academicLevels[1].value,
    _typeOfPaper: typeOfPaper || orderItems.typesOfPaper[1].value,
    _deadline: deadline || orderItems.deadlines[0].value,
    _time: orderItems.time[0].value,
    _subject: orderItems.subjects[1].value,
    _spacing: "double",
    _pageCount: pageCount || 1,
    _style: style || orderItems.style[1].value,
  });

  useEffect(() => {
    // Update passedValues when URL parameters change
    setPassedValues({
      _academicLevel: academicLevel || orderItems.academicLevels[1].value,
      _typeOfPaper: typeOfPaper || orderItems.typesOfPaper[1].value,
      _deadline: deadline || orderItems.deadlines[0].value,
      _time: orderItems.time[0].value,
      _subject: orderItems.subjects[1].value,
      _spacing: "double",
      _pageCount: pageCount || 1,
      _style: style || orderItems.style[1].value,
    });
  }, [academicLevel, typeOfPaper, deadline, pageCount, style]);

  return (
    <FullWidthLayout pageTitle="Create Order">
      <div className="row">
        <div className="col-md-8">
          <div className="card">
            <OrderWizard passedValues={passedValues} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-2">
            <div className="card-body">
              <p>Click the link below for online class and exam help.</p>
              <div className="banner-btn" data-cues="slideInUp">
                <a href="/onlineclasshelp" className="default-btn active">
                  <div className="d-flex align-items-center">Place Order</div>
                </a>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h2>Privacy & Security</h2>
              <p>
                We value our clients' privacy and treat all personal information
                as private and confidential.
              </p>
              <h2>Original Content</h2>
              <p>
                Plagiarism is something we do not tolerate. We use multiple
                plagiarism scanners to ensure the originality of the work you
                receive, every time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </FullWidthLayout>
  );
};

export default Order;
