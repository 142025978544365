import React from "react";
import exam3 from "../../images/exam3.jpg";

const HomeOnline = () => {
  return (
    <div className="generate-area pb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className="global-content generate-content mw-668 position-relative z-2"
              data-cues="slideInUp"
            >
              <span className="top-title">We Are Eager To Help</span>
              <h2>Best custom writing services</h2>
              <p>
                Get high-quality papers from professional writers in no time! We
                offer top-quality custom essays within tight deadlines. We
                understand the importance of offering unique essay writing
                services, and that’s why at essaypearl , we guarantee
                high-quality essays free from plagiarism and grammatical errors.
                Here, our professional essay writers will meet all your writing
                needs, helping you be in control over your studies 24/7. Having
                your paper done by the industry’s best experts is essential to
                ensure you get the best academic paper. That is why we handpick
                a writer based on their specialization area to write a suitable
                essay for each order.
              </p>
              {/* <ul className="generate-list list-unstyled ps-0 mb-0">
                  <li className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="flaticon-bot"></i>
                    </div>
                    <div className="flex-grow-1">
                      <span>
                        Save hundreds of hours with our AI article generator.
                      </span>
                    </div>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="flaticon-bot"></i>
                    </div>
                    <div className="flex-grow-1">
                      <span>
                        Create article that are complete in less than 10
                        seconds.
                      </span>
                    </div>
                  </li>
                </ul> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="generate-img position-relative z-1"
              data-cues="slideInRight"
            >
              <img src={exam3} alt="generate-img" />
              {/* <img
                src="assets/images/shape/shape-11.png"
                className="shape shape-11"
                alt="shape-11"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOnline;
