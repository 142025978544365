import React, { useState, useEffect } from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import "./blog.css";
import { useParams } from "react-router-dom";
import blogImg from "../../images/blog.jpg";
import blogAuthorImg from "../../images/author.png";
import ReactHtmlParser from "react-html-parser"; // Import ReactHtmlParser to parse HTML content
const fileBaseUrl = process.env.REACT_APP_FILEBASEURL;
const apiUrl = process.env.REACT_APP_APIURL;

const BlogItem = () => {
  const { postname } = useParams();
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState(null); // Change to null initially

  const formatPostName = (postname) => {
    return postname
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const postCleanName = formatPostName(postname);

useEffect(() => {
  fetch(`${apiUrl}web/getblogitem/${postCleanName}`)
    .then((response) => response.json())
    .then((data) => {
      if (data && data.b_title) {
        const extractedBlog = {
          id: data.blog_id,
          title: data.b_title,
          image: data.b_image
            ? `${fileBaseUrl}blog/${data.b_image}`
            : blogImg,
          author: data.b_author,
          authorImage: data.b_authorimage
            ? `${fileBaseUrl}blog/${data.b_authorimage}`
            : blogAuthorImg,
          dateAdded: data.b_dateadded,
          content: data.b_content,
          tags: JSON.parse(data.b_tags),
        };
        setBlog(extractedBlog);
      } else {
        setBlog(null);
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching blog info:", error);
      setLoading(false);
    });
}, [postCleanName]);
const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return new Date(dateString).toLocaleString("en-US", options);
};

  return (
    <div>
      <SideBarPageLayout pageTitle={postCleanName}>
        {loading ? (
          <p>Loading...</p>
        ) : blog ? ( // Check if blog is not null before rendering
          <div>
            <img src={blog.image} className="blogitem-img" alt={blog.title} />
            <div className="row mt-2">
              <div className="col-md-8">
                <h2>{blog.title}</h2>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-6 float-right">
                    <img
                      src={blog.authorImage} // Use blog author image here
                      className="blogauthoritem-img"
                      alt={blog.author}
                    />
                  </div>
                  <div className="col-md-6">
                    <p className="fw-bold text-primary mb-0 mt-1">
                      {blog.author}
                    </p>
                    <p className="text-small text-muted">
                      {formatDate(blog.dateAdded)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="mt-2">{ReactHtmlParser(blog.content)}</div>{" "}
            {/* Parse HTML content */}
            <div className="mt-2">
              <h3>Tags:</h3>
              <div>
                {blog.tags.map((tag, index) => (
                  <span key={index} className="badge bg-secondary mr-2">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="alert alert-warning">
              Sorry the article you are looking for could not be found
            </div>
            <a
              href="/blog"
              className="default-btn active "
              style={{ backgroundColor: "green" }}
            >
              View More Articles
            </a>
          </div>
        )}
      </SideBarPageLayout>
    </div>
  );
};

export default BlogItem;
