import React from "react";
import pricingItems from "../../components/lists/pricingitems";

const PricingTable = ({ selectedType }) => {
  const selectedTypeData = pricingItems.find(
    (item) => item.type === selectedType
  );

  if (!selectedTypeData) {
    return <p>No pricing data available for the selected type.</p>;
  }

  return (
    <div>
      <div class="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr className="table-active">
              <th scope="col">Deadline</th>
              <th scope="col">High School</th>
              <th scope="col">Undergraduate (yrs. 1-2)</th>
              <th scope="col">Undergraduate (yrs. 3-4)</th>
              <th scope="col">Master's</th>
              <th scope="col">Ph. D.</th>
            </tr>
          </thead>
          <tbody>
            {selectedTypeData.data.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {rowData.map((cellData, cellIndex) => (
                  <td key={cellIndex}>{cellData}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingTable;
