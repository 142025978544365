import React, { useState, useEffect } from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import "./blog.css";
import blogImg from "../../images/blog.jpg";
import { Link } from "react-router-dom";
const apiUrl = process.env.REACT_APP_APIURL;
const fileBaseUrl = process.env.REACT_APP_FILEBASEURL;

const Blog = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 8;
  const maxVisiblePages = 10; 

  useEffect(() => {
    fetch(`${apiUrl}web/getblogs?page=${currentPage}&perPage=${perPage}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          const extractedBlogs = data.data.map((item) => ({
            id: item.b_title.replace(/\s+/g, "-").toLowerCase(),
            title: item.b_title,
            image: item.b_image
              ? `${fileBaseUrl}blog/${item.b_image}`
              : blogImg,
            categories: item.b_category
              .map((category) => category.bc_name)
              .join(", "),
          }));
          setBlogs(extractedBlogs);
          setTotalPages(data.totalPages);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= maxVisiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(maxVisiblePages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxVisiblePages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxVisiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <SideBarPageLayout pageTitle="Blog">
        <h2>Our Articles</h2>
        <p>
          You don't have to worry about pressing deadlines and sleepless nights.
          Our essay writing service pros love to share valuable tips, making
          your life much simpler. Some of tips and a lot of other useful
          information, you can find on our blog. So, enjoy the goldmine of
          information and ace your academic assignments stress-free.
        </p>
        <hr></hr>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {blogs.length === 0 ? (
              <div className="alert alert-warning" role="alert">
                We have not published any blogs yet please check back later.
              </div>
            ) : (
              <div>
                <div className="row">
                  {blogs.map((item) => (
                    <div
                      className="col-md-3 mb-3 d-flex align-items-stretch"
                      key={item.id}
                    >
                      <div className="card">
                        <img
                          src={item.image}
                          className="card-img-top blogimg"
                          alt={item.title}
                        />
                        <div className="card-body d-flex flex-column blog-body">
                          <h6>{item.title}</h6>
                          <p className="text-small text-primary">
                            {item.categories}
                          </p>
                          <Link
                            className="mt-auto btn btn-sm btn-outline-primary align-self-start"
                            to={`/blog/${item.id}`}
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2">
                  <nav aria-label="Page navigation mt-2">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        )}
      </SideBarPageLayout>
    </div>
  );
};

export default Blog;
