import React, { useState, useContext } from "react";
import menuItems from "../../lists/menuItems";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../../images/logo.png";
import { AuthContext } from "../../utils/AuthContext";

const NavbarMenu = () => {
  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);
  const expand = "lg";
  const { isAuthenticated, logout } = useContext(AuthContext);
  
  console.log("isAuthenticated", isAuthenticated);

  const toggleMobileNavbar = () => {
    setIsMobileNavbarOpen(!isMobileNavbarOpen);
  };

  const updatedMenuItems = isAuthenticated
    ? [...menuItems, { label: "Dashboard", link: "/dashboard" }]
    : menuItems;

  return (
    <nav className="navbar navbar-expand-lg fixed-top" id="navbar">
      <div className="container-fluid">
        <Navbar.Brand href="/">
          <img src={logo} className="main-logo" alt="white-logo" />
          <img src={logo} className="white-logo d-none" alt="white-logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          onClick={toggleMobileNavbar}
        />
        <Navbar.Offcanvas
          show={isMobileNavbarOpen}
          onHide={() => setIsMobileNavbarOpen(false)}
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
          className={isMobileNavbarOpen ? "for-mobile-navbar" : ""}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <a className="navbar-brand" href="/">
                <img src={logo} className="main-logo" alt="white-logo" />
                <img
                  src={logo}
                  className="white-logo d-none"
                  alt="white-logo"
                />
              </a>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {!isMobileNavbarOpen ? (
              <Nav className="justify-content-center flex-grow-1 pe-3">
                {updatedMenuItems.map((menuItem, index) => (
                  <React.Fragment key={index}>
                    {menuItem.subItems ? (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href={menuItem.link || "#"}
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>{menuItem.label}</span>
                        </a>
                        <ul className="dropdown-menu">
                          {menuItem.subItems.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <a className="dropdown-item" href={subItem.link}>
                                {subItem.label}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <a className="nav-link" href={menuItem.link || "#"}>
                          <span>{menuItem.label}</span>
                        </a>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </Nav>
            ) : (
              <div className="accordion" id="navbarAccordion">
                {updatedMenuItems.map((menuItem, index) => (
                  <div className="accordion-item" key={index}>
                    {menuItem.subItems ? (
                      <div>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-${index}`}
                          aria-expanded="false"
                        >
                          {menuItem.label}
                        </button>
                        <div
                          id={`collapse-${index}`}
                          className="accordion-collapse collapse"
                        >
                          <div className="accordion-body">
                            <div
                              className="accordion"
                              id={`navbarAccordion-${index}`}
                            >
                              {menuItem.subItems.map((subItem, subIndex) => (
                                <div key={subIndex} className="accordion-item">
                                  <a
                                    href={subItem.link}
                                    className="accordion-link"
                                  >
                                    {subItem.label}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <a
                        href={menuItem.link}
                        className="accordion-button without-icon"
                      >
                        {menuItem.label}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="others-options">
              <ul>
                <li>
                  {isAuthenticated ? (
                    <a href="/order" className="default-btn">
                      <span>Create Order</span>
                    </a>
                  ) : (
                    <a href="/login" className="default-btn">
                      <span>Login Now</span>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </div>
    </nav>
  );
};

export default NavbarMenu;
