import React from "react";
import cards from "../../../images/Payments.png";
const FooterCopyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="copy-right-area bg-color-161a1e">
      <div className="container">
        <div className="copy-right-content">
          <img src={cards} alt="logo" />
          <p>
            © {currentYear} All Rights Reserved <span>EssayPearl</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterCopyright;
