import React from "react";
import CustomBreadCrump from "../../components/utils/breadcrump";
import CalculatorForm from "../../components/forms/CalculatorForm";
import PricingTabs from "./PricingTabs";
import SideBarPageLayout from "../../components/utils/sidebarlayout";

const Pricing = () => {
  return (
    <div>
      <SideBarPageLayout pageTitle="Pricing">
        <h2>Online Class Help</h2>
        <h3>Full class help</h3>
        <p>
          For online classes we have an amazing offer of 100-160 per week on
          this package we do all assignments and exams due within 7 days
          regardless of the amount of assignments, quizzes or exams per week.
        </p>
        <h3>Exams</h3>
        <p>
          We charge 100-240$ per exam, please chat with support to get the exact
          quote. We do proctored exams.
        </p>
        <h3>Pricing</h3>
        <PricingTabs />
        <p>
          <b>Please note: </b>all prices in the table are presented in US
          dollars, but any other currency will be automatically converted when
          you make the payment. The prices are specified without the VAT. It
          will be charged additionally if you live in the EU.
        </p>
      </SideBarPageLayout>
    </div>
  );
};

export default Pricing;
