import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../utils/AuthContext";
const apiUrl = process.env.REACT_APP_APIURL;

const LoginFormAuth = ({ onLoginChange }) => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (username) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(username);
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setError("Please fill in all fields.");
      return;
    }

    if (!validateEmail(username)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}auth/login`, {
        username,
        password,
      });

      const { email, fullname, jwt, expiry } = response.data;

      localStorage.setItem("email", email);
      localStorage.setItem("fullname", fullname);
      localStorage.setItem("essay_jwt", jwt);
      localStorage.setItem("expiry", expiry);

      console.log("Login successful:", response.data);
      setError(null);
      login();
      onLoginChange(true);
    } catch (error) {
      console.error("Login error:", error);
      setError(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-12 mb-2">
          <label>Email Address:</label>
          <input
            type="text"
            className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>Password:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <button type="button" className="default-btn" onClick={handleLogin}>
            {loading ? "Logging in..." : "Login"}
          </button>
          {error && <div className="alert alert-danger mt-2">{error}</div>}
        </div>
      </div>
    </form>
  );
};

export default LoginFormAuth;
