const pricingItems = [
  {
    type: 1,
    data: [
      ["14 Days", "$8.99", "$9.99", "$10.99", "$11.99", "$14.99"],
      ["7 Days", "$9.00", "$11.00", "$12.00", "$12.00", "$19.00"],
      ["5 Days", "$10.00", "$12.00", "$13.00", "$17.00", "$23.00"],
      ["3 Days", "$11.00", "$13.00", "$13.00", "$18.00", "$28.00"],
      ["2 Days", "$12.00", "$14.00", "$14.00", "$19.00", "$30.00"],
      ["24 Hours", "$15.00", "$15.00", "$15.00", "$21.00", "$34.00"],
      ["8 Hours", "$16.00", "$17.00", "$18.00", "$23.00", "$42.00"],
    ],
  },
  {
    type: 2,
    data: [
      ["14 Days", "$4.50", "$5.50", "$7.00", "$8.99", "$12.49"],
      ["7 Days", "$6.50", "$7.50", "$8.50", "$10.00", "$14.00"],
      ["5 Days", "$7.00", "$8.50", "$9.50", "$11.00", "$15.00"],
      ["3 Days", "$7.50", "$9.50", "$10.50", "$12.00", "$16.00"],
      ["2 Days", "$8.50", "$10.00", "$11.00", "$13.00", "$17.00"],
      ["24 Hours", "$10.00", "$11.50", "$12.50", "$14.50", "$20.00"],
      ["8 Hours", "$11.50", "$13.50", "$14.50", "$18.00", "$24.50"],
    ],
  },
  {
    type: 3,
    data: [
      ["14 Days", "$11.69", "$14.29", "$18.19", "$23.39", "$32.49"],
      ["7 Days", "$16.90", "$19.50", "$22.10", "$26.00", "$36.40"],
      ["5 Days", "$18.20", "$22.10", "$24.70", "$28.60", "$39.00"],
      ["3 Days", "$19.50", "$24.70", "$27.30", "$31.20", "$41.60"],
      ["2 Days", "$22.10", "$26.00", "$28.60", "$33.80", "$44.20"],
      ["24 Hours", "$26.00", "$29.90", "$32.50", "$37.70", "$52.00"],
      ["8 Hours", "$29.90", "$35.10", "$37.70", "$46.80", "$63.70"],
    ],
  },
  {
    type: 4,
    data: [
      ["14 Days", "$21.58", "$26.38", "$33.58", "$43.18", "$59.98"],
      ["7 Days", "$31.20", "$36.00", "$40.80", "$48.00", "$67.20"],
      ["5 Days", "$33.60", "$40.80", "$45.60", "$52.80", "$72.00"],
      ["3 Days", "$36.00", "$45.60", "$50.40", "$57.60", "$76.80"],
      ["2 Days", "$40.80", "$48.00", "$52.80", "$62.40", "$81.60"],
      ["24 Hours", "$48.00", "$55.20", "$60.00", "$69.60", "$96.00"],
      ["8 Hours", "$55.20", "$64.80", "$69.60", "$86.40", "$117.60"],
    ],
  },
];

export default pricingItems;
