const testimonialItems = [
  {
    name: "Customer 48807 ",
    subject: "Nursing",
    avatar:
      "https://images.pexels.com/photos/2381069/pexels-photo-2381069.jpeg?cs=srgb&dl=pexels-emmy-e-2381069.jpg&fm=jpg",
    text: "The service provided exceeded my expectations in terms of reliability and performance. The individual's dedication to delivering high-quality results for any task is commendable. I am thoroughly impressed and confident in recommending their services to others seeking top-notch assistance.",
    rating: 5,
  },
  {
    name: "Customer 50499",
    subject: "Business  Management",
    avatar:
      " https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg",
    text: "The quality of the content delivered was outstanding, showcasing a deep understanding of the subject matter and impeccable writing skills. The efficient communication and timely delivery further solidified my trust in their capabilities. I would highly recommend this platform for anyone seeking reliable and high-quality writing services!",
    rating: 5,
  },
  {
    name: "Client 60557",
    subject: "E-commerce",
    avatar:
      " https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?cs=srgb&dl=pexels-pixabay-39866.jpg&fm=jpg",
    text: "I always use this website for a time-sensitive project, and their promptness and professionalism were impressive. The writer exhibited a keen grasp of the topic, delivering well-researched and eloquently written content. The overall experience was seamless, making it a go-to platform for anyone in need of efficient and top-notch writing services..",
    rating: 5,
  },
  {
    name: "Customer 65572",
    subject: "mathematics",
    avatar:
      "https://t3.ftcdn.net/jpg/02/00/90/24/360_F_200902415_G4eZ9Ok3Ypd4SZZKjc8nqJyFVp1eOD6V.jpg",
    text: "I reached out to this service for assistance with an online test, and I couldn't be more pleased with the results. I achieved an A grade, and I am incredibly grateful for the invaluable support provided. Highly recommended for anyone seeking academic assistance!!",
    rating: 5,
  },
  {
    name: "Customer 300567",
    subject: "Accounting",
    avatar:
      " https://images.pexels.com/photos/6209073/pexels-photo-6209073.jpeg?cs=srgb&dl=pexels-cottonbro-studio-6209073.jpg&fm=jpg",
    text: "Thanks to the expert guidance and support for my proctored exam, I achieved an impressive A grade, exceeding my expectations. Their professionalism, reliability, and dedication to helping clients succeed make them a standout choice for anyone in need of online exam assistance. I highly recommend their services.!",
    rating: 5,
  },
  {
    name: "Customer 27634",
    subject: "Law",
    avatar:
      " https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnYgyb6L6CdIurJ6DHanE3PbyM9Y_ozmrqOP6Z9-zYzkqXj4jRHqkKw4teOq4pqDXl1fE&usqp=CAU",
    text: "This service is completely trustworthy!  They did my essay and it was on time without mistakes and of a really high quality. Also the support managers offered me a discount for the next help with my essay which is really pleasant. I think I have found my favorite essay writing services!",
    rating: 5,
  },
  {
    name: "Customer 38865",
    subject: "Nursing",
    avatar:
      " https://media.istockphoto.com/id/1339089882/video/businessman-thinking-and-looking-for-answer-shrugs-his-shoulders-uncertainty-question-no.jpg?s=640x640&k=20&c=K0andV7egGGYxsw0sFUn6FwwkdPBHLQOIP9AOkON5dk=",
    text: "They did a fantastic job on my paper. I was definitely stressed to get my paper in on time considering it was due in 5 hours, but he did a great job in about 2 hours! Definitely recommend.!",
    rating: 5,
  },
  {
    name: "Customer 76578",
    subject: "Essay",
    avatar:
      " https://people.com/thmb/IR5L_SKYyPSRGZt6KfqCxxb-EYc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(1039x465:1041x467)/paul-mescal-6-c4d582a586274fdea3063ccfebea0081.jpg",
    text: "I order essays weekly basis. I work fulltime and attend evening classes so have little time for homework. Having these folks doing these essays gives me time to spend with my wife. thank you essay pearl, you’re a life saver.",
    rating: 5,
  },
  {
    name: "Customer 22045",
    subject: "Computer science",
    avatar:
      " https://media.istockphoto.com/id/1173548737/photo/confident-business-girl-smiling-at-camera-crossing-hands-white-background.jpg?s=612x612&w=0&k=20&c=yjN0EsUsQL1cSRyLG40qS37wHis8i6qvlDMDDHDbuFw=",
    text: "I have to commend this service for their exceptional work in assisting with my computer science assignment. The project was not only delivered on time but also showcased a high level of accuracy and quality. The support team was incredibly helpful, I highly recommend them for their reliability and quality work.!",
    rating: 5,
  },
  {
    name: "Customer 22398",
    subject: "Math Exam",
    avatar:
      "https://img.freepik.com/free-photo/vertical-shot-attractive-african-american-male-smiling-camera_181624-36894.jpg",
    text: "This service has proven to be incredibly reliable and proficient in helping me with my math exam. I'm delighted to have discovered such a dependable service for my math exams, and I highly recommend them to others seeking Math solutions assistance.!",
    rating: 5,
  },
];

export default testimonialItems;