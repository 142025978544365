const faqItems = [
  {
    title: "How secure is your service in terms of confidentiality?",
    content:
      "At our service, we value our clients' privacy and take measures to protect it. Your personal information is kept confidential and is only used for communication purposes. We do not disclose any details to third parties without your explicit consent. Furthermore, our experts do not have access to any client information, and clients do not have access to our writers' information, except for their ID in our system. We prioritize the security of personal information to ensure a safe and reliable experience.",
  },
  {
    title:
      "What is the estimated turnaround time for completing an assignment?",
    content:
      "The time it takes to complete an assignment depends on several factors such as the type of assignment, its complexity, and the deadline. However, our team of experts works diligently to ensure that all assignments are completed within the given timeframe. We understand the importance of meeting deadlines, and we strive to deliver quality work on time.",
  },
  {
    title: "What can I do if I am not satisfied with the work?",
    content:
      "If for any reason you are not satisfied with the quality of work produced by our team, we offer revisions free of charge. Our goal is to ensure that you are completely satisfied with the work delivered to you. If the revisions do not meet your expectations, we offer a refund under certain circumstances.",
  },
  {
    title: "Do you have a money-back guarantee?",
    content:
      "Yes, we offer a money-back guarantee if we are unable to deliver the work within the given timeframe or if the work produced does not meet the quality standards outlined in our policies. Our clients' satisfaction is our top priority, and we strive to ensure that all issues are resolved satisfactorily.",
  },
  {
    title: "Where are your writers located?",
    content:
      "Our team of best essay writers is composed of professionals from various parts of the world. We hire best writers who are native speakers of the language they will be writing in and have extensive experience in their respective fields. We ensure that our writers are qualified and capable of producing high-quality work that meets our clients' expectations.",
  },
  {
    title: "How can I receive my completed paper?",
    content:
      "Once your paper is ready, it will be available for download from your account on our platform. You will receive a notification email with a link to your completed assignment. You can also request to receive your paper via email or have a hard copy mailed to you for an additional fee.",
  },
  {
    title: "How original is the work produced by your service?",
    content:
      "We take plagiarism very seriously and ensure that all work produced by our team is 100% original. Our writers use reliable sources and cite them correctly to avoid any instances of plagiarism. We also use plagiarism detection software to verify the originality of our work before delivering it to clients.",
  },
];

export default faqItems;
