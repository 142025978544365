import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import orderItems from "../../lists/orderItems";
import "react-datepicker/dist/react-datepicker.css";

const First = ({ selectedValues, onSelectedValuesChange }) => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedDeadline, setSelectedDeadline] = useState(new Date());
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedClassData, setClassData] = useState({
    loginLink: "",
    username: "",
    password: "",
    instructions: "",
  });

  useEffect(() => {
    setSelectedType(selectedValues._type);
    setSelectedDeadline(selectedValues._deadline || new Date());
    setSelectedSubject(selectedValues._subject);
    setClassData(selectedValues._classData || {});
  }, [selectedValues]);

  const handleSelectChange = (key, value) => {
    let updatedClassData = { ...selectedClassData };

    switch (key) {
      case "_type":
        setSelectedType(value);
        onSelectedValuesChange("_type", value);
        break;
      case "_deadline":
        setSelectedDeadline(value);
        onSelectedValuesChange("_deadline", value);
        break;
      case "_subject":
        setSelectedSubject(value);
        onSelectedValuesChange("_subject", value);
        break;
      default:
        updatedClassData = { ...updatedClassData, [key]: value };
        setClassData(updatedClassData);
        onSelectedValuesChange("_classData", updatedClassData);
        break;
    }
  };


  const onlinetypes = orderItems.onlinetype;
  const subjectOptions = orderItems.subjects;

  const handleDateTimeChange = (date) => {
    setSelectedDeadline(date);
    onSelectedValuesChange("_deadline", date);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6 mb-2">
          <label>Select Type</label>
          <Select
            options={onlinetypes}
            value={{
              value: selectedType,
              label: selectedType,
            }}
            onChange={(selectedOption) =>
              handleSelectChange("_type", selectedOption.value)
            }
            placeholder="Select Type"
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Select Subject</label>
          <Select
            options={subjectOptions}
            value={{
              value: selectedSubject,
              label: selectedSubject,
            }}
            onChange={(selectedOption) =>
              handleSelectChange("_subject", selectedOption.value)
            }
            placeholder="Select Subject"
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Select Deadline</label>
          <br />
          <DatePicker
            className="form-control"
            selected={selectedDeadline}
            onChange={handleDateTimeChange}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Link to Login</label>
          <input
            type="text"
            className="form-control"
            value={selectedClassData.loginLink}
            onChange={(e) => handleSelectChange("loginLink", e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Login Username</label>
          <input
            type="text"
            className="form-control"
            value={selectedClassData.username}
            onChange={(e) => handleSelectChange("username", e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Login Password</label>
          <input
            type="password"
            className="form-control"
            value={selectedClassData.password}
            onChange={(e) => handleSelectChange("password", e.target.value)}
          />
        </div>
        <div className="col-md-12 mb-2">
          <label>Instructions</label>
          <textarea
            placeholder="Enter instructions here"
            className="form-control"
            value={selectedClassData.instructions}
            onChange={(e) => handleSelectChange("instructions", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default First;
