import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import axios from "axios";
const apiUrl = process.env.REACT_APP_APIURL;

const DashboardPayments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch payments data from an endpoint
    fetchPaymentsData();
  }, []);

  const fetchPaymentsData = async () => {
    try {
      const token = localStorage.getItem("essay_jwt");
      const response = await axios.get(`${apiUrl}user/mypayments`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setPayments(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching payments data:", error);
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "Order",
      accessor: "p_order",
    },
    {
      Header: "Amount",
      accessor: "p_amount",
      Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}`,
    },
    {
      Header: "Email",
      accessor: "p_email",
    },
    {
      Header: "Mode",
      accessor: "p_mode",
    },
    {
      Header: "Transaction ID",
      accessor: "p_transid",
    },
    {
      Header: "Transaction Time",
      accessor: "p_transtime",
    },
    {
      Header: "Date Added",
      accessor: "p_dateadded",
    },
  ];


  return (
    <div>
      <h2>Payments</h2>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <ReactTable
          data={payments}
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      )}
    </div>
  );
};

export default DashboardPayments;
