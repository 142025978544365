import React from "react";
import CustomBreadCrump from "../../components/utils/breadcrump";


const NotFound = () => {
  return (
    <div>
      <CustomBreadCrump pagetitle="Error 404" />
      <div className="container" style={{padding:"80px 50px", marginTop:"100px"}}>
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card">
              <div className="card-body">
                <h2 className="mt-2">404 - Not Found</h2>
                <p className="mt-2">Sorry, the page you are looking for does not exist.</p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
