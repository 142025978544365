import React from "react";
import testimonialItems from "../../components/lists/testimonialsItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";


const HomeTestimonials = () => {
  return (
    <div className="standard-area ptb-100">
      <div className="container">
        <div
          id="carouselExampleControls"
          className="carousel slide text-center carousel-dark"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {testimonialItems.map((testimonial, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <img
                  className="rounded-circle shadow-1-strong mb-4"
                  src={testimonial.avatar}
                  alt="avatar"
                  style={{ width: "100px", height: "100px", objectFit: "cover" }}
                />
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-8">
                    <h5 className="mb-3">{testimonial.name}</h5>
                    <p>{testimonial.subject}</p>
                    <p className="text-muted">
                      <i className="fas fa-quote-left pe-2"></i>
                      {testimonial.text}
                    </p>
                  </div>
                </div>
                <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                  {[...Array(5)].map((_, i) => (
                    <li key={i}>
                      {i < testimonial.rating ? (
                        <FontAwesomeIcon icon={faStar} />
                      ) : (
                        <FontAwesomeIcon icon={farStar} />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeTestimonials;
