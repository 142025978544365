import React from "react";

const homeWork = () => {
  return (
    <div className="powerful-area position-relative z-1 pt-100 pb-70">
      <div className="container">
        <div className="row justify-content-center" data-cues="slideInUp">
          <div className="col-md-12">
            <div className="">
              <span className="top-title">How It Works</span>
              <h2 className="">How we help with teas exam or proctored exam</h2>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <p className="ft-15">
              To allow us to take your online proctored test, you'll first need
              to have the software in the device you'll be using to take the
              exam. Install the program; when you've completed the process,
              you'll have to inform us of the exam date and time a few days in
              advance. That offers us ample time to prep for the exam and ensure
              everything is in order. That takes a maximum of about 10 minutes.{" "}
              <br></br>On the test day, we recommend that you be online at least
              25 minutes before the exam to link our software to your device.
              Our proctoring professional will help you do that in readiness for
              the exam session. When the exam starts, all you need to do is to
              handle the preliminary procedures, such as ID and face
              verifications, hardware set-up, and room phone, among others. Once
              that's done, your remote proctor will feed in the password, and
              the exam will begin.
            </p>
            <p className="">
              Our professional will be able to see the questions as soon as they
              appear on your screen through our software, and s/he will take
              over and answer the question in real-time. To your remote proctor,
              it'll seem as if you're the one doing, but it'll be us. Your only
              job will be to sit in front of the screen and simulate you're
              working on the paper. <br></br>That means moving the cursor or
              mouse whenever necessary. At first, you don't have to move the
              cursor. We recommend that you wait for us to read and answer the
              question and then proceed to another page, all that time
              pretending to be busy working. We'll establish a regular interval
              when our professional will start answering the questions with
              every turn of the page. Remember not to move the mouse as we
              answer the questions to ensure everything runs smoothly. We will
              answer all the questions before time, and then we'll submit them.
              You can then rest easy, knowing that you'll get an excellent grade
              on the test.
            </p>
          </div>
          <div className="col-md-6 mt-2">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="single-unlock" data-cues="slideInUp">
                  <h5>Steps to Access Our Proctored Exam Services</h5>
                  <p>
                    We provide students and professionals a straightforward
                    process of accessing our "Take My Online Proctored Exam for
                    Me" services. They are as follows:
                  </p>
                  <div class="unlock-grid">
                    <p>
                      <b>Step 1:</b> Pay for the service and confirm the payment
                    </p>
                  </div>
                  <div class="unlock-grid">
                    <p>
                      <b>Step 2:</b> We'll send you our software link. Download
                      and install it, and we'll run a trial with you.
                    </p>
                  </div>
                  <div class="unlock-grid">
                    <p>
                      <b>Step 3:</b> Send the information on the exam date and
                      time
                    </p>
                  </div>
                  <div class="unlock-grid">
                    <p>
                      <b>Step 4:</b> We'll agree to the date and time and book
                      it in our schedule.
                    </p>
                  </div>
                  <div class="unlock-grid">
                    <p>
                      <b>Step 5:</b> On the test day, we'll require you to be
                      online 15 minutes before the start time so that we connect
                      to your screen and ensure everything is in place.
                    </p>
                  </div>
                  <div class="unlock-grid">
                    <p>
                      <b>Step 6:</b> The exam starts. For straightforward
                      communications, add our experts to your WhatsApp contacts
                      and chat with them directly
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <img
        src="assets/images/shape/shape-9.png"
        className="shape shape-9"
        alt="shape-9"
      /> */}
    </div>
  );
};

export default homeWork;
