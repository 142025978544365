import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ChatBox from "../../../../components/widgets/ChatBox";
import UploadAssignmentForm from "../../../../components/forms/UploadAssignmentForm";
import OrderAssignments from "../../../../components/widgets/OrderAssignments";
import OrderSolutions from "../../../../components/widgets/OrderSolutions";
const apiUrl = process.env.REACT_APP_APIURL;

const ViewOrder = ({ orderNumber }) => {
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const token = localStorage.getItem("essay_jwt");
        const response = await axios.get(
          `${apiUrl}orders/orderDetails/${orderNumber}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setOrderData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order data:", error);
        setLoading(false);
        navigate("/dashboard/orders");
      }
    };

    fetchOrderData();
  }, [orderNumber]);

 return (
   <div>
     {loading ? (
       <div>Loading...</div>
     ) : (
       <div className="row">
         <div className="col-md-7 mt-2">
           <div className="card">
             <div className="card-body p-4">
               <h4>
                 ORDER #{orderData.order_id}{" "}
                 <span
                   className={`badge rounded-pill ${
                     orderData.o_status === 0
                       ? "bg-warning" // Pending - Yellow
                       : orderData.o_status === 1
                       ? "bg-info" // Submitted - Blue
                       : orderData.o_status === 2
                       ? "bg-primary" // Assigned - Primary
                       : orderData.o_status === 3
                       ? "bg-secondary" // Revision - Secondary
                       : orderData.o_status === 4
                       ? "bg-success" // Completed - Green
                       : ""
                   }`}
                 >
                   {orderData.o_status === 0
                     ? "Pending Payment"
                     : orderData.o_status === 1
                     ? "Submitted"
                     : orderData.o_status === 2
                     ? "Assigned"
                     : orderData.o_status === 3
                     ? "Revision"
                     : orderData.o_status === 4
                     ? "Completed"
                     : ""}
                 </span>
               </h4>
               {orderData.o_type === "Writing Service" && (
                 <p>
                   <span className="fw-bold">Topic:</span>{" "}
                   <span className="text-primary">{orderData.o_topic}</span>
                   <br />
                   <span className="fw-bold">Type of assignment:</span>{" "}
                   <span className="text-primary">{orderData.o_type}</span>
                   <br />
                   <span className="fw-bold">Number of pages:</span>{" "}
                   <span className="text-primary">{orderData.o_pages}</span>
                   <br />
                   <span className="fw-bold">Academic level:</span>{" "}
                   <span className="text-primary">
                     {orderData.o_academiclevel}
                   </span>
                   <br />
                   <span className="fw-bold">Subject:</span>{" "}
                   <span className="text-primary">{orderData.o_subject}</span>
                   <br />
                   <span className="fw-bold">Paper format:</span>{" "}
                   <span className="text-primary">{orderData.o_style}</span>
                   <br />
                   <span className="fw-bold">Line spacing:</span>{" "}
                   <span className="text-primary">{orderData.o_spacing}</span>
                   <br />
                   <span className="fw-bold">No of Sources:</span>{" "}
                   <span className="text-primary">
                     {orderData.o_references}
                   </span>
                   <br />
                   <span className="fw-bold">Deadline:</span>{" "}
                   <span className="text-primary">{orderData.o_duedate}</span>
                   <br />
                   <span className="fw-bold">Cost:</span>{" "}
                   <span className="text-primary">${orderData.o_total}</span>
                   <br />
                   <span className="fw-bold">Instructions:</span>
                   <br />
                   <span className="text-primary">
                     {orderData.o_instructions}
                   </span>
                 </p>
               )}
               {orderData.o_type === "Online Tutoring" && (
                 <p>
                   <span className="fw-bold">Subject:</span>{" "}
                   <span className="text-primary">
                     {orderData.o_classdata
                       ? JSON.parse(orderData.o_classdata).subject
                       : ""}
                   </span>
                   <br />
                   <span className="fw-bold">Type:</span>{" "}
                   <span className="text-primary">
                     {orderData.o_classdata
                       ? JSON.parse(orderData.o_classdata).type
                       : ""}
                   </span>
                   <br />
                   <span className="fw-bold">Login Link:</span>{" "}
                   <span className="text-primary">
                     {orderData.o_classdata
                       ? JSON.parse(orderData.o_classdata).loginLink
                       : ""}
                   </span>
                   <br />
                   <span className="fw-bold">Username:</span>{" "}
                   <span className="text-primary">
                     {orderData.o_classdata
                       ? JSON.parse(orderData.o_classdata).username
                       : ""}
                   </span>
                   <br />
                   <span className="fw-bold">Password:</span>{" "}
                   <span className="text-primary">
                     {orderData.o_classdata
                       ? JSON.parse(orderData.o_classdata).password
                       : ""}
                   </span>
                   <br />
                   <span className="fw-bold">Instructions:</span>
                   <br />
                   <span className="text-primary">
                     {orderData.o_classdata
                       ? JSON.parse(orderData.o_classdata).instructions
                       : ""}
                   </span>
                 </p>
               )}
               <OrderAssignments
                 orderData={orderData}
                 orderNumber={orderNumber}
               />
               <OrderSolutions
                 orderData={orderData}
                 orderNumber={orderNumber}
               />
             </div>
           </div>
         </div>
         <div className="col-md-5 mt-2">
           {orderData.o_status === 0 && (
             <div className="card mb-2">
               <div className="card-body">
                 <div className="alert alert-warning text-center">
                   Kindly pay for the order so that it can be submitted
                   <br></br>
                   <a
                     href={`/dashboard/orders/${orderData.order_id}/payment`}
                     className="btn btn-warning"
                   >
                     Proceed to payment
                   </a>
                 </div>
               </div>
             </div>
           )}
           <UploadAssignmentForm
             orderData={orderData}
             orderNumber={orderNumber}
           />
           <ChatBox orderData={orderData} orderNumber={orderNumber} />
         </div>
       </div>
     )}
   </div>
 );

};

export default ViewOrder;
